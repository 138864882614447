<template>
  <section class="app-container">
    <Filter ref="filterRef" @search="handleSearch" @refresh-table="handleResetTable" :selectedIdDate="selectedIdDate"
      @changeassignmentStaffIdOps="changeassignmentStaffIdOps($event)" @toadd="handleAdd" :OpsData="OpsData" @toDisabled="handeldisabled"
    />
    <SkillList ref="skillListRef" @toedit="handleEdit($event)" @handleSelectedIdDate="handleSelectedIdDate"></SkillList>
  </section>
  <EditModal v-if="visible" :visible="visible" :taskType="taskType" :modalData="modalData" :OpsData="OpsData"
    @changeassignmentStaffIdOps="changeassignmentStaffIdOps($event)" @toCancel="handleCancel($event)" @getStaffIdOps="getEmpoweredPerson"
             @doSearch="handleSearch"
  ></EditModal>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>
<script>
import {defineComponent, ref, onMounted} from "vue";
import Filter from "./components/Filter.vue";
import SkillList from "./components/List";
import EditModal from "./components/EditModal";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";
import ModalBox from '@/components/ModalBox'


export default defineComponent({
  name: "roleAuthorization",
  components: {
    SkillList,
    Filter,
    EditModal,
    ModalBox
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const skillListRef = ref();
    const filterRef = ref();
    const handleSearch = (searchParams) => {
      skillListRef.value.searchClick(searchParams);
      getEmpoweredPerson()
    };
    const handleResetTable = () => {
      skillListRef.value.doSearch();
      getEmpoweredPerson()
    };
    const OpsData = ref({
      assignmentStaffIdOps: [],
      assignmentStaff: [],
      empoweredStaffIdOps: [],
      allempoweredStaffIdOps: [],
      engagementCodeOps: [],
      codeStaffRelations: [],
      pmcodeStaffRelations:[],
      statusOps: [
        { label: '失效', value: '1' },
        { label: '生效', value: '0' },
      ]
    })
    //被授权人查询
    const getEmpoweredPerson = () => {
      $api.getEmpoweredPerson().then(res => {
        OpsData.value.empoweredStaffIdOps = res.data
      })
    }
    //被授权人新建
    const findAllStaffs = () => {
      $api.findAllStaffs().then(res => {
        OpsData.value.allempoweredStaffIdOps = res.data
      })
    }
    //code和人员数组
    const getcodeStaffItems = () => {
      $api.getcodeStaffItems().then(res => {
        OpsData.value.engagementCodeOps = res.data.engagementCode
        OpsData.value.assignmentStaffIdOps = OpsData.value.assignmentStaff = res.data.assignmentStaff
      })
    }
    //code和人员对应关系
    const getcodeStaffRelations = () => {
      $api.getcodeStaffRelations().then(res => {
        OpsData.value.codeStaffRelations = res.data
      })
    }
    //code和人员对应关系(筛选项)
    const getPMCodeAndAssignment = () => {
      $api.getPMCodeAndAssignment().then(res => {
        OpsData.value.pmcodeStaffRelations = res.data
      })
    }
    //更新调令人员
    const changeassignmentStaffIdOps = (data) => {
      if (data === 'all') {
        OpsData.value.assignmentStaffIdOps = OpsData.value.assignmentStaff
      } else {
        OpsData.value.assignmentStaffIdOps = data
      }

    }
    const visible = ref(false)
    const taskType = ref('')
    const modalData = ref({})
    const handleEdit = (data) => {
      modalData.value = data
      taskType.value = 'edit'
      visible.value = true
    }
    const handleAdd = () => {
      modalData.value = {}
      taskType.value = 'add'
      visible.value = true
    }
    const handleCancel = (str) => {
      visible.value = false
      if (str === 'doSearch') {
        filterRef.value.doSearch()
      }
    }
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const handleOk = () => {
      $api.batchStopEmpower(selectedIdDate.value).then(() => {
        message.success('操作成功')
        selectedIdDate.value = []
        skillListRef.value.cancelSelectd();
        skillListRef.value.doSearch();
      })
    }
    const handeldisabled = () => {
      content.value = '请确定是否停用？'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = selectedIdDate.value
      modelValue.value = true
      /*Modal.confirm({
        title: "请确认是否停用？",
        icon: createVNode(ExclamationCircleOutlined),
        centered: true,
        closable: true,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          $api.batchStopEmpower(selectedIdDate.value).then(() => {
            message.success('操作成功')
            selectedIdDate.value = []
            skillListRef.value.cancelSelectd();
            skillListRef.value.doSearch();
          })
        },
      });*/
    }
    const selectedIdDate = ref([])
    const handleSelectedIdDate = (data) => {
      selectedIdDate.value = data
    }
    onMounted(() => {
      getEmpoweredPerson()
      findAllStaffs()
      getcodeStaffItems()
      getcodeStaffRelations()
      getPMCodeAndAssignment()
    })
    return {
      skillListRef,
      handleSearch,
      handleResetTable,
      handleEdit,
      visible,
      taskType,
      modalData,
      OpsData,
      getEmpoweredPerson,
      handleAdd,
      handleCancel,
      findAllStaffs,
      getcodeStaffItems,
      getcodeStaffRelations,
      getPMCodeAndAssignment,
      changeassignmentStaffIdOps,
      filterRef,
      handeldisabled,
      handleSelectedIdDate,
      selectedIdDate,
      modelValue,
      showIconCom,
      content,
      handleOk,
    };
  },
});
</script>
