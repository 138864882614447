<template>
  <section class="org-list">
    <a-table ref="skillListRef" :columns="columns" :data-source="skillList" row-key="id" :rowClassName="rowClassNameFunc"
      :scroll="{
        y: tableHeight,
        x: 1000,
        scrollToFirstRowOnChange: true,
      }"
      :row-selection="{
        selectedRows: selectedState.selectedList,
        selectedRowKeys: selectedState.selectedId,
        onSelect: onSelect,
        onChange: onSelectChange,
        getCheckboxProps: getCheckboxPropsFunc,
      }"
      :pagination="pagination">
      <template #index="{ index }">
        <span>{{ index + 1 + (pagination.current - 1) * 10}}</span>
      </template>
      <template #status="{ text }">
        <a-tag :class="`status ${text === '0' ? '生效' : '失效'}`">
          {{text === '0' ? '生效' : '失效'}}
        </a-tag>
      </template>
      <template #operationTime="{ record }">
        <span>{{ record.operationTime }}</span>
      </template>
      <template #operation="{ record }">
<!--        <a-space>
&lt;!&ndash;          <span  v-auth="['system:roleauthorization:edit']" :class="`${record.status === '0' ? 'operationLink underline' : 'operationLink underline disabled'}`"&ndash;&gt;
&lt;!&ndash;            @click="updateClick(record)">编辑</span>&ndash;&gt;
          &lt;!&ndash; <span class="operationLink" @click="updateClick(record)">编辑</span> &ndash;&gt;
          <span  v-auth="['system:roleauthorization:stop']" :class="`${record.status === '1' ? 'redLink underline disabled' : 'redLink underline'}`"
            @click="stopClick(record)">停用</span>
        </a-space>-->
        <ActionBtn v-bind="record" :btnList="btnList" @MinusCircleOutlined="stopClick(record)" />
      </template>
    </a-table>
  </section>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import ModalBox from '@/components/ModalBox'
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '停用',
    icon: 'MinusCircleOutlined',
    auth: ['system:roleauthorization:stop']
  },
]

export default defineComponent({
  name: "CostCtrList",
  components: {ModalBox, ActionBtn},
  emits: ['toedit','handleSelectedIdDate'],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const skillListRef = ref();
    const columns = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        ellipsis: true,
        width: 80,
        slots: { customRender: "index" },
      },
      {
        title: "被授权人",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        width: 260,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        ellipsis: true,
        width: 200,
      },
      // {
      //   title: "调令人员",
      //   dataIndex: "assigmentPerson",
      //   key: "assigmentPerson",
      //   ellipsis: true,
      //   align: "center",
      //   width: 150,
      // },
      // {
      //   title: "授权时限",
      //   dataIndex: "empowerDate",
      //   key: "empowerDate",
      //   ellipsis: true,
      //   align: "center",
      //   width: 200,
      //   slots: { customRender: "empowerDate" },
      // },
      {
        title: "授权状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width: 150,
        slots: { customRender: "status" },
      },
      {
        title: "更新时间",
        dataIndex: "operationTime ",
        key: "operationTime ",
        ellipsis: true,
        slots: { customRender: "operationTime" }
      },
      {
        title: "操作",
        dataIndex: "operation",
        key: "operation",
        ellipsis: true,
        width: 80,
        slots: { customRender: "operation" },
        fixed: "right",
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const skillList = ref();
    let searchCondition = {};
    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      };
      $api.getPmEmpowerData(params).then((res) => {
        // res.data.resource.forEach(item => {
        //   item.assignmentStaffIdList = item.assigmentIdStr.split('; ')
        // })
        pagination.total = res.data.total;
        skillList.value = res.data.resource;
      });
    };
    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };
    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    const updateClick = (record) => {
      ctx.emit("toedit", record);
    }
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {record} = modalData.value
      let params = {
        mark: record.mark,
        id: record.id,
        email: record.email,
        pmId: record.pmId,
      }
      $api.stopEmpower(params).then(() => {
        doSearch(searchCondition);
      })
    }
    const stopClick = (record) => {
      content.value = '请确定是否停用？'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = {record}
      modelValue.value = true
      /*Modal.confirm({
        title: '请确定是否停用？',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '确定',
        onOk() {
          let params = {
            id:record.id
          }
          $api.stopEmpower(params).then(() => {
            message.success('操作成功')
            doSearch(searchCondition);
          })
        },
        cancelText: '取消',
      });*/
    }
    const selectedState = reactive({
      selectedId: [],
      selectedList: [],
    });
    const onSelect = (record, selected) => {
      if (selected) {
        selectedState.selectedList.push(record);
      } else {
        const delIndex = selectedState.selectedList.findIndex((val) => {
          return val.id === record.id;
        });
        selectedState.selectedList.splice(delIndex, 1);
      }
      ctx.emit("handleSelectedIdDate", selectedState.selectedId);
    };
    const onSelectChange = (selectedRowKeys) => {
      selectedState.selectedId = selectedRowKeys;
      ctx.emit("handleSelectedIdDate", selectedState.selectedId);
    };
    const getCheckboxPropsFunc = (record) => {
      return {
        disabled: record.status === '1'
      };
    };
    const cancelSelectd = () => {
      selectedState.selectedId = []
    }

    const rowClassNameFunc = (record) => {
      if (record.status) {
        if (record.status === '1') {
          return "deactivate-row";
        }
      }
      return null;
    };
    return {
      skillListRef,
      columns,
      pagination,
      skillList,
      searchClick,
      tableHeight,
      doSearch,
      updateClick,
      stopClick,
      modelValue,
      content,
      showIconCom,
      handleOk,
      selectedState,
      onSelect,
      onSelectChange,
      getCheckboxPropsFunc,
      cancelSelectd,
      rowClassNameFunc,
      btnList
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  >tr>th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  >tr>td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }

  .status{
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.生效 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }
    &.失效 {
      color: #999999;
      background: #CCCCCC 60%;
    }
  }
}
:deep(.ant-table-body) {
  .deactivate-row {
    background: #f4f4f4 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #f4f4f4 !important;
    }
  }
}
</style>
