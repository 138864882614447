<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef">
        <template #customform>
          <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
            <a-form-item label="被授权人" name="empoweredStaffId" class="rms-form-item">
              <SelectWithAll v-model:value="formState.empoweredStaffIdList" placeholder="请选择" mode="multiple" showArrow
                             allowClear :maxTagCount="1" optionFilterProp="label" @dropdownVisibleChange="empoweredStaffDropdown">
                <a-select-option v-for="(item, index) in propOpsData.allempoweredStaffIdOps" :key="index"
                                 :value="item.empoweredStaffId" :label="item.email"><span :title="item.email">{{
                    item.email }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Engagement Code" name="engagementCodeList" class="rms-form-item">
              <SelectWithAll v-model:value="formState.engagementCodeList" placeholder="请选择" mode="multiple" showArrow
                             allowClear :maxTagCount="1" @change="changeEngagementCode" @dropdownVisibleChange="engagementCodeDropdown">
                <a-select-option v-for="(item, index) in propOpsData.engagementCodeOps" :key="index" :value="item"><span
                    :title="item">{{
                    item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <!--          <a-form-item label="调令人员" name="assignmentStaffId" class="rms-form-item">-->
            <!--            <SelectWithAll v-model:value="formState.assignmentStaffId" placeholder="请选择" mode="multiple" showArrow-->
            <!--              allowClear :maxTagCount="1" optionFilterProp="label">-->
            <!--              <a-select-option v-for="(item, index) in OpsData.assignmentStaffIdOps" :key="index" :value="item.userId"-->
            <!--                :label="item.fullName"><span :title="item.fullName">{{-->
            <!--                  item.fullName }}</span>-->
            <!--              </a-select-option>-->
            <!--            </SelectWithAll>-->
            <!--          </a-form-item>-->
            <a-form-item label="授权状态" name="status" class="rms-form-item">
              <SelectWithAll v-model:value="formState.status" placeholder="请选择" mode="multiple" showArrow allowClear
                             :maxTagCount="1" optionFilterProp="label">
                <a-select-option v-for="(item, index) in OpsData.statusOps" :key="index" :value="item.value"
                                 :label="item.label"><span :title="item">{{
                    item.label }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="停用">
              <a-button v-auth="['system:roleauthorization:batchdeactive']" type="primary" danger @click="handeldisabled" :disabled="selectedIdDate.length === 0">
                <StopOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="新增">
              <a-button class="ml12" v-auth="['system:roleauthorization:add']" type="primary" @click="handeladd">
                <PlusOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import {defineComponent, reactive, ref, onActivated, watch} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { PlusOutlined,StopOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'

export default defineComponent({
  name: "TopFilter",
  components: {
    Tooltip,
    PlusOutlined,
    StopOutlined
  },
  props: {
    OpsData: {
      type: Object,
      default: () => ({})
    },
    selectedIdDate: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ["search", "refresh-table", "changeassignmentStaffIdOps"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    let currentParams = {}
    const updateParams = () => {
      currentParams = {
        ...formState
      }
    }
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const assignmentTypeOps = ref([]);
    const formState = reactive({
      empoweredStaffIdList: [],
      engagementCodeList: [],
      status: [],
      id: []
    });
    const resetForm = () => {
      Object.assign(formState, {
        empoweredStaffIdList: [],
        engagementCodeList: [],
        status: [],
        id:[]
      })
      updateParams()
      ctx.emit("search", currentParams);
    };
    const orgThreeTwoChange = () => {
      formState.orgFourOneOneList = [];
      if (formState.orgThreeTwoList.length) {
        $api
          .getOrgFour({ condition: formState.orgThreeTwoList.join() })
          .then((res) => {
            orgFourOneOneOps.value = res.data.orgFourOneOne;
          });
      }
      // doSearch();
    };
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentParams);
    };
    const handeladd = () => {
      ctx.emit("toadd", currentParams);
    };
    const handeldisabled = () => {
      ctx.emit("toDisabled", currentParams);
    }
    onActivated(() => {
      ctx.emit("refresh-table");
    });
    const changeEngagementCode = () => {
      let arr = []
      if (formState.engagementCodeList.length > 0) {
        formState.engagementCodeList.forEach((Item) => {
          props.OpsData.pmcodeStaffRelations.forEach(item => {
            if (Item === item.engagement_code) {
              props.OpsData.assignmentStaff.forEach(i => {
                if (item.user_id === i.userId) {
                  console.log(i);
                  arr.push(i)
                }
              })
            }
          })
        })
        ctx.emit("changeassignmentStaffIdOps", Func(arr, 'userId'))
      } else {
        ctx.emit("changeassignmentStaffIdOps", 'all')
      }

    }
    //去重
    const Func = (arr, userId) => {
      const res = new Map();
      return arr.filter((item) => !res.has(item[userId]) && res.set(item[userId], 1));
    }
    const propOpsData = ref({
      allempoweredStaffIdOps: [],
      engagementCodeOps: []
    })
    const empoweredStaffDropdown = (state) => {
      if(state === true) {
        let empoweredStaff = propOpsData.value.allempoweredStaffIdOps.filter(item => formState.empoweredStaffIdList.includes(item.empoweredStaffId))
        propOpsData.value.allempoweredStaffIdOps = empoweredStaff.concat(propOpsData.value.allempoweredStaffIdOps.filter(item => !formState.empoweredStaffIdList.includes(item.empoweredStaffId)))
      }
    }
    const engagementCodeDropdown = (state) => {
      if(state === true) {
        let empoweredStaff = propOpsData.value.engagementCodeOps.filter(item => formState.engagementCodeList.includes(item))
        propOpsData.value.engagementCodeOps = empoweredStaff.concat(propOpsData.value.engagementCodeOps.filter(item => !formState.engagementCodeList.includes(item)))
      }
    }
    watch(
        () => props.OpsData,
        (val) => {
          if(val){
            propOpsData.value.allempoweredStaffIdOps = props.OpsData.empoweredStaffIdOps;
            propOpsData.value.engagementCodeOps = props.OpsData.engagementCodeOps;
          }
        },
        {immediate:true,deep:true}
    )
    return {
      formState,
      formRef,
      updateParams,
      doSearch,
      resetForm,
      handeladd,
      orgThreeTwoOps,
      orgFourOneOneOps,
      assignmentTypeOps,
      orgThreeTwoChange,
      changeEngagementCode,
      Func,
      handeldisabled,
      empoweredStaffDropdown,
      propOpsData,
      engagementCodeDropdown,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  :deep(.ant-select-selection-overflow-item){
    max-width: 73%;
  }
  :deep(.ant-select-selection-overflow) {
    flex-wrap: nowrap;
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
  /*.red{
    background: #ff4d4f;
    border: 1px solid#ff4d4f;;
  }*/
}
</style>
