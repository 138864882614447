<template>
  <div ref="editModalRef">
    <a-modal
        :getContainer="()=>$refs.editModalRef"
        @cancel="handleCancel"
        width="40.6vw"
        :visible="visible"
        :title="taskType === 'edit' ? '编辑授权' : '新增授权'"
        centered
    >
      <a-form ref="formRef" layout="vertical" :model="formState" :rules="formRules" :wrapperCol="{ span: 22 }">
        <a-row>
          <a-col :span="12">
            <a-form-item label="被授权人" name="empoweredStaffId">
              <a-select :disabled="taskType === 'edit'" placeholder="请选择" @dropdownVisibleChange="empoweredStaffDropdown"
                        mode="multiple" v-model:value="formState.empoweredStaffId" optionFilterProp="label" @select="empoweredStaffChange"
                        show-search allowClear :maxTagCount="1" showArrow :maxTagTextLength="1"
              >
                <a-select-option v-for="(item, index) in propOpsData.allempoweredStaffIdOps" :key="index" :value="item.id"
                                 :label="item.email"><span :title="item.fullName + ' ' + item.email">{{ item.fullName + " " + item.email }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Engagement Code" name="engagementCode">
              <SelectWithAll placeholder="请选择" v-model:value="formState.engagementCode" allowClear showArrow @dropdownVisibleChange="engagementCodeDropdown"
                             @change="changeEngagementCode" mode="multiple">
                <a-select-option v-for="(item, index) in propOpsData.engagementCodeOps" :key="index" :value="item"><span
                    :title="item">{{
                    item
                  }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </a-col>
<!--          <a-col :span="12">
            <a-form-item label="调令人员" name="assignmentStaffIdList">
              <SelectWithAll v-model:value="formState.assignmentStaffIdList" placeholder="请选择" mode="multiple" showArrow
                             optionFilterProp="label" allowClear :maxTagCount="1">
                <a-select-option v-for="(item, index) in OpsData.assignmentStaffIdOps" :key="index" :label="item.fullName"
                                 :value="item.userId"><span :title="item">{{
                    item.fullName }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="授权时限" name="time">
              <a-range-picker :placeholder="['开始日期', '结束日期']" v-model:value="formState.time" :disabledDate="disabledDate" />
            </a-form-item>
          </a-col>-->
        </a-row>
      </a-form>
      <template #footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleConfirm">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { cloneDeep } from "lodash";
import { message } from "ant-design-vue";

import {
  defineComponent,
  onMounted,
  watch,
  ref,
} from "vue";
export default defineComponent({
  name: "editModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({
      }),
    },
    taskType: {
      type: String,
      default: ''
    },
    OpsData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["toCancel", "changeassignmentStaffIdOps", "getStaffIdOps", "doSearch"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formState = ref({
      assignmentStaffIdList: [],
      empoweredStaffId: [],
      engagementCode: [],
      time: []
    })
    const formRef = ref();
    const disabledDate = (current) => {
      return current < moment().subtract(1, 'day').endOf('day');
    }
    const formRules = {
      empoweredStaffId: [
        {
          required: true,
          message: "请选择被授权人",
          trigger: "change",
          type: 'array',
        },
      ],
      engagementCode: [
        {
          required: true,
          message: "请选择Engagement Code",
          trigger: "change",
          type: 'array',
        },
      ],
      assignmentStaffIdList: [
        {
          required: true,
          type: 'array',
          message: "请选择调令人员",
          trigger: "change",
        },
      ],
      time: [
        {
          required: true,
          type: 'array',
          message: "请输入授权时限",
          trigger: "change",
        },
      ],
    }
    const handleCancel = () => {
      ctx.emit("toCancel")
    }
    const handleConfirm = () => {
      formRef.value
        .validate()
        .then(() => {
          if (props.taskType === 'edit') {
            $api.staffPmEmpowerEdit(formState.value).then(() => {
              message.success('操作成功')
              ctx.emit("toCancel", 'doSearch','getStaffIdOps')
            })
          } else {
            const params = {
              empoweredStaffIdList:formState.value.empoweredStaffId,
              engagementCodeList: formState.value.engagementCode
            }
            $api.staffPmEmpowerAdd(params).then(() => {
              message.success('操作成功')
              ctx.emit("toCancel", 'doSearch','getStaffIdOps')
            })
          }
        })
    }
    let frist = true
    const changeEngagementCode = () => {
      let arr = []
      if (frist) {
        frist = false
      } else {
        formState.value.assignmentStaffIdList = []
      }
      if (formState.value.engagementCode) {
        props.OpsData.codeStaffRelations.forEach(item => {
          if (formState.value.engagementCode === item.engagementCode) {
            props.OpsData.assignmentStaff.forEach(i => {
              if (item.userId === i.userId) {
                console.log(i);
                arr.push(i)
              }
            })
          }
        })
        ctx.emit("changeassignmentStaffIdOps", Func(arr, 'userId'))
      } else {
        ctx.emit("changeassignmentStaffIdOps", 'all')
      }
    }
    const empoweredStaffChange = (selectVal) => {
      const params = {
        empoweredStaffId: selectVal
      }
      $api.checkPMRoleMutex(params).then((res) => {
        if(res.data){
          message.error('所选人员存在和PM互斥的角色，无法授权')
          const index = formState.value.empoweredStaffId.indexOf(selectVal);
          formState.value.empoweredStaffId.splice(index, 1);
        }
      });
    }
    const propOpsData = ref({
      allempoweredStaffIdOps: [],
      engagementCodeOps: []
    })
    const empoweredStaffDropdown = (state) => {
      if(state === true) {
        let empoweredStaff = propOpsData.value.allempoweredStaffIdOps.filter(item => formState.value.empoweredStaffId.includes(item.id))
        propOpsData.value.allempoweredStaffIdOps = empoweredStaff.concat(propOpsData.value.allempoweredStaffIdOps.filter(item => !formState.value.empoweredStaffId.includes(item.id)))
      }
    }
    const engagementCodeDropdown = (state) => {
      if(formState.value.engagementCode && state === true) {
        let empoweredStaff = propOpsData.value.engagementCodeOps.filter(item => formState.value.engagementCode.includes(item))
        propOpsData.value.engagementCodeOps = empoweredStaff.concat(propOpsData.value.engagementCodeOps.filter(item => !formState.value.engagementCode.includes(item)))
      }
    }
    //去重
    const Func = (arr, userId) => {
      const res = new Map();
      return arr.filter((item) => !res.has(item[userId]) && res.set(item[userId], 1));
    }
    onMounted(() => {
      changeEngagementCode()
    })
    watch(
      () => props.modalData,
      (val) => {
        formState.value = cloneDeep(val)
      },
      { immediate: true }
    )
    watch(
      () => formState.value.time,
      (value) => {
        console.log(value);
        if (value) {
          formState.value.empowerStartDate = value[0]
            ? value[0].format("YYYY-MM-DD")
            : null;
          formState.value.empowerEndDate = value[1]
            ? value[1].format("YYYY-MM-DD")
            : null;
        }
      }
    );
    watch(
        () => props.visible,
        (val) => {
          if(val){
            propOpsData.value.allempoweredStaffIdOps = props.OpsData.allempoweredStaffIdOps;
            propOpsData.value.engagementCodeOps = props.OpsData.engagementCodeOps;
          }
        },
        {immediate:true,deep:true}
    )
    return {
      formState,
      disabledDate,
      handleCancel,
      changeEngagementCode,
      Func,
      formRules,
      formRef,
      handleConfirm,
      empoweredStaffChange,
      empoweredStaffDropdown,
      engagementCodeDropdown,
      propOpsData,

    }
  }
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
</style>
